var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `${_vm.extCourseSectionId}_${_vm.key}`,
      staticClass: "course-home",
      class: [_vm.isHotTable ? "p-0 m-1" : ""],
    },
    [_c("keep-alive", [_c("router-view")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }