<template>
<div
    :key="`${extCourseSectionId}_${key}`"
    class="course-home"
    :class="[ isHotTable ? 'p-0 m-1' : '' ]"
>
    <keep-alive>
        <router-view />
    </keep-alive>
</div>
</template>

<script lang="ts">

export default {
    name: 'CourseHome',
    data() {
        return {
            key: 1,
        };
    },
    computed: {
        subHeader() {
            return this.$route.meta.subHeader;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        isHotTable() {
            return this.$route.name == 'CourseGradebook' || this.$route.name == 'CourseAttendance';
        },
    },
    watch: {
        '$route.name': {
            handler() {
                this.key += 1;
            },
        },
        extCourseSectionId() {
            this.key += 1;
        },
    },
};
</script>
